<template>
    <div>
    <el-form :inline="false" label-width="100px">
        <el-form-item label="编号:">
            {{callData.QuestionsCode}}
        </el-form-item>
        <el-form-item label="题目:">
            {{callData.QuestionsTitle}}
        </el-form-item>
        <el-form-item label="难度系数:">
            <!-- {{callData.difficulty}} -->
            <!-- 高中低 -->
            <template>
                <el-tag
                  :type="'danger'"
                  disable-transitions v-if="callData.Difficulty== 0">{{"高"}}
                </el-tag>
                <el-tag
                  :type="'success'"
                  disable-transitions v-if="callData.Difficulty== 1">{{"中"}}
                </el-tag>
                <el-tag
                  :type="''"
                  disable-transitions v-if="callData.Difficulty== 2">{{"低"}}
                </el-tag>
            </template>
        </el-form-item>
        <el-form-item label="题型:">
            <template>
                <el-tag
                  :type="''"
                  disable-transitions v-if="callData.QuestionsType == 0">{{"单选题"}}
                </el-tag>
                <el-tag
                  :type="'success'"
                  disable-transitions v-if="callData.QuestionsType == 1">{{"多选题"}}
                </el-tag>
                <el-tag
                  :type="'warning'"
                  disable-transitions v-if="callData.QuestionsType == 2">{{"判断题"}}
                </el-tag>
            </template>
        </el-form-item>
        <el-form-item label="答案:" v-if="callData.QuestionsType == 0">
            <el-radio-group v-model="optionJudge" class="answer" style="display: flex;flex-direction: column;">
                <el-radio disabled v-for="item in callData.Options" :key="item.index" :label="item.IsAnswer" style="padding:10px;">{{item.AnswerContent}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="答案:" v-if="callData.QuestionsType == 1">
            <el-checkbox-group class="answer" v-model='answerArr' style="display: flex;flex-direction: column;">
                <el-checkbox disabled v-for="item in callData.Options" :key="item.index" :label="item.AnswerContent" style="padding:1px;">{{item.AnswerContent}}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="答案:" v-if="callData.QuestionsType == 2">
            <el-radio-group disabled v-model="optionJudge" class="answer">
                <el-radio :label="true">正确</el-radio>
                <el-radio :label="false">错误</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="解析:">
            {{callData.Analysis}}
        </el-form-item>
        <el-form-item label="状态:">
            <template>
                <el-tag
                  :type="'success'"
                  disable-transitions v-if="callData.IsEnable== true">{{"启用"}}
                </el-tag>
                <el-tag
                  :type="'danger'"
                  disable-transitions v-if="callData.IsEnable== false">{{"禁用"}}
                </el-tag>
            </template>
        </el-form-item>
    </el-form>
    </div>
</template>

<script>
export default {
    name: "convertDiceq",
    props: ["dataObj"], //接受父组件传值
    data() {
        return{
            callData: '',
            optionJudge: '',
            answerArr: []
        }
    },
    watch: {
        'dataObj': function(o){
            this.callData = o
            if(o.QuestionsType == 2){
                for(var i = 0; i < o.Options.length; i++){
                    if(o.Options[0].IsAnswer){
                        this.optionJudge = true
                    }else if(o.Options[1].IsAnswer){
                        this.optionJudge = false
                    }
                }
            }else if(o.QuestionsType == 1){
                for(var j = 0;j < o.Options.length; j++){
                    if(o.Options[j].IsAnswer){
                        this.answerArr.push(o.Options[j].AnswerContent)
                    }
                }
            }else if(o.QuestionsType == 0){
                this.optionJudge = true
            }
        }
    },
    mounted() {
    }
}
</script>

<style lang="stylus" scoped>
    .answer>>>.el-radio__input.is-disabled+span.el-radio__label{
        color #000!important
    }
    .answer>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #000!important
    }
</style>