<template>
    <div>
    <el-form :inline="false" label-width="100px" :model='callData' disabled>
        <el-form-item label='项目编号：'>
            <el-input v-model='callData.ExamProjectNumber'></el-input>
        </el-form-item>
        <el-form-item label='项目名称：'>
            <el-input v-model='callData.ExamProjectName'></el-input>
        </el-form-item>
        <el-form-item label='项目介绍：'>
            <el-input v-model='callData.ExamProjectNotes' type='textarea'></el-input>
        </el-form-item>
        <el-form-item label='考试流程：'>
            <el-input v-model='callData.ExamProcedure' type='textarea'></el-input>
        </el-form-item>
        <el-form-item label='试卷名称：'>
            <el-input v-model='callData.ExamPaperName'></el-input>
        </el-form-item>
    </el-form>
    </div>
</template>

<script>
export default {
    name: "public",
    props: ["dataObj"], //接受父组件传值
    data() {
        return{
            callData: {},
            optionJudge: '',
            answerArr: []
        }
    },
    watch: {
        'dataObj': function(o){
            this.callData = o
        }
    },
    methods:{
    },
    mounted() {
    }
}
</script>

<style lang="stylus" scoped>
    .answer>>>.el-radio__input.is-disabled+span.el-radio__label{
        color #000!important
    }
    .answer>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #000!important
    }
</style>