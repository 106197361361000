<template>
    <div>
        <el-form :inline="false" label-width="100px">
            <el-form-item label="审批记录名称:">
                {{callData.RecordName}}
            </el-form-item>
            <el-form-item label="审批流程:">
                {{callData.ApprovalProcessName}}
            </el-form-item>
            <el-form-item label="说明:">
                {{callData.Remarks}}
            </el-form-item>
        </el-form>
        <el-table
        :data="tableData1"
        border
        style="width: 100%">
            <el-table-column prop="ApprovalUserName" label="审批人" align="center" width="200">
                <template slot-scope="scope">
                    <el-tag
                    :type="''"
                    disable-transitions>{{scope.row.ApprovalUserName}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="NodeName" label="审批步骤" width=""></el-table-column>
            <el-table-column prop="ApprovalComments" label="审批意见"> </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: "approvalRecord",
    props: ["dataObj"], //接受父组件传值
    data() {
        return {
            callData: '',
            tableData1: [],
        }
    },
    watch: {
        'dataObj': function(o){
            this.callData = o
            this.tableData1 = o.ApprovalSteps
        }
    },
}
</script>

<style lang="stylus" scoped>

</style>